<template>
    <div>
        <div class="sideMenu-title mb-2">
            통계관리
        </div>
        <div class="sideMenu">
            <!--<ul style="width:100%;" class="default-Btn-1 my-12 hidden-sm-and-down">-->
            <div class="sideMenu-group">
                <ul style="width:100%;" class="">
                    <li class="">
                        <a :class="{active : menuActive.isActive0}" @click="menuClick('/admin/stat/desc/price')">매출현황</a>
                    </li>
                    <li class="">
                        <a :class="{active : menuActive.isActive1}" @click="menuClick('/admin/stat/desc/count')">방문횟수</a>
                    </li>
                    <li class="">
                        <a :class="{active : menuActive.isActive2}" @click="menuClick('/admin/stat/desc/payment')">결제방법</a>
                    </li>
                    <li class="">
                        <a :class="{active : menuActive.isActive3}" @click="menuClick('/admin/stat/chart')">매출그래프</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>

</template>

<script>
    export default {
        name: 'AdminStatMenu',
        data: () => ({
            menuActive: {
                isActive0: false,
                isActive1: false,
                isActive2: false,
                isActive3: false,
            }
        }),
        created: function () {
            this.setMenuActive();
        },
        computed: {

        },
        methods: {
            menuClick(route) {

                location.href = route;
            },
            setMenuActive(){
                switch (window.location.pathname) {
                    case "/admin/stat/desc/price":{
                        this.menuActive.isActive0 = true;
                        break;
                    }
                    case "/admin/stat/desc/count":{
                        this.menuActive.isActive1 = true;
                        break;
                    }
                    case "/admin/stat/desc/payment":{
                        this.menuActive.isActive2 = true;
                        break;
                    }
                    case "/admin/stat/chart":{
                        this.menuActive.isActive3 = true;
                        break;
                    }
                }

            }
        },

    }

</script>

<style lang="scss" scoped>
    /*@import './base.scss';*/


</style>
