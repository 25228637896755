<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div style="">
        <v-row>
            <v-col>
                <v-container class="my-5 adminContainer">
                    <v-row>
                        <v-col cols="0" md="2">
                            <admin-stat-menu></admin-stat-menu>
                        </v-col>
                        <v-col cols="12" md="10">
                            <v-row>
                                <v-col>
                                    <table class="table-left-s table-left-s-b">
                                        <colgroup>
                                            <col style="width:15%"/>
                                            <col style="width:85%"/>
                                        </colgroup>
                                        <tr>
                                            <th class="th">객실구분</th>
                                            <td class="td">
                                                <input type="radio" id="" value="" v-model="roomGubun"
                                                       style="width: 20px; height: 12px;">
                                                <label for="">전체</label>
                                                <input type="radio" id="R" value="R" v-model="roomGubun"
                                                       style="width: 20px; height: 12px;">
                                                <label for="R">객실</label>
                                                <input type="radio" id="A" value="A" v-model="roomGubun"
                                                       style="width: 20px; height: 12px;">
                                                <label for="A">캠핑장A</label>
                                                <input type="radio" id="B" value="B" v-model="roomGubun"
                                                       style="width: 20px; height: 12px;">
                                                <label for="B">캠핑장B</label>
                                                <input type="radio" id="C" value="C" v-model="roomGubun"
                                                       style="width: 20px; height: 12px;">
                                                <label for="C">캠핑장C</label>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th rowspan="2">검색기간</th>
                                            <td>
                                                <v-btn
                                                        color="primary"
                                                        class="mx-1 white--text center-block tmp-btn"
                                                        x-small
                                                        @click="setSearchBtn(1)"
                                                >
                                                    1개월
                                                </v-btn>
                                                <v-btn
                                                        color="primary"
                                                        class="mx-1 white--text center-block tmp-btn"
                                                        x-small
                                                        @click="setSearchBtn(2)"
                                                >
                                                    2개월
                                                </v-btn>
                                                <v-btn
                                                        color="primary"
                                                        class="mx-1 white--text center-block tmp-btn"
                                                        x-small
                                                        @click="setSearchBtn(3)"
                                                >
                                                    3개월
                                                </v-btn>
                                                <v-btn
                                                        color="primary"
                                                        class="mx-1 white--text center-block tmp-btn"
                                                        x-small
                                                        @click="setSearchBtn(6)"
                                                >
                                                    6개월
                                                </v-btn>
                                                <v-btn
                                                        color="primary"
                                                        class="mx-1 white--text center-block tmp-btn"
                                                        x-small
                                                        @click="setSearchBtn(12)"
                                                >
                                                    12개월
                                                </v-btn>
                                                <v-btn
                                                        color="primary"
                                                        class="mx-1 white--text center-block tmp-btn"
                                                        x-small
                                                        @click="setSearchBtn(0)"
                                                >
                                                    초기화
                                                </v-btn>
                                            </td>
                                        </tr>
                                        <tr>
                                            <!--<th class="th">검색기간</th>-->
                                            <td class="td">
                                                <input id="startDate" style="width: 150px;"
                                                       v-model="searchDate.startDate" readonly>~
                                                <input id="endDate" style="width: 150px;" v-model="searchDate.endDate"
                                                       readonly>
                                                <!--<input type="radio" id="Y" value="N" v-model="searchDate.isRegDate"
                                                       style="width: 20px; height: 12px;">
                                                <label for="Y">예약일</label>
                                                <input type="radio" id="N" value="Y" v-model="searchDate.isRegDate"
                                                       style="width: 20px; height: 12px;">
                                                <label for="N">등록일</label>-->
                                                <v-btn
                                                        color="primary"
                                                        class="mx-3 white--text center-block tmp-btn"
                                                        x-small
                                                        @click="searchClick"
                                                >
                                                    검색
                                                </v-btn>
                                            </td>
                                        </tr>

                                    </table>
                                </v-col>
                                <v-col cols="12" class="sub-title-3 mt-2" style="display: flex;">
                                    예약통계
                                </v-col>
                                <!--<v-col class="hidden-md-and-down">-->
                                <div class="overflow-x-auto">
                                    <table class="adminListTable">
                                        <colgroup>
                                            <col style="width:20%"/>
                                            <col style="width:20%"/>
                                            <col style="width:20%"/>
                                            <col style="width:20%"/>
                                            <col style="width:20%"/>
                                        </colgroup>
                                        <tr>
                                            <th>신용카드</th>
                                            <th>계좌이체</th>
                                            <th>가상계좌</th>
                                            <th>무통장입금</th>
                                            <th>합계</th>
                                        </tr>
                                        <tr>
                                            <td>{{statInfo.resvPrice_3 | comma}}원</td>
                                            <td>{{statInfo.resvPrice_2 | comma}}원</td>
                                            <td>{{statInfo.resvPrice_1 | comma}}원</td>
                                            <td>{{statInfo.resvPrice_0 | comma}}원</td>
                                            <td>{{statInfo.resvPrice_tot | comma}}원</td>
                                        </tr>
                                    </table>
                                </div>
                            </v-row>
                            <v-row>
                                <v-col>
                                    ※입금완료된 내역만 표시됩니다.
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>

                </v-container>
            </v-col>
        </v-row>

    </div>
</template>

<script>
    import AdminStatMenu from "../../components/AdminStatMenu";
    export default {
        name: 'AdminStatMain',
        components: {AdminStatMenu},
        data: () => ({

            statInfo: {
                resvPrice_0: 0,
                resvPrice_1: 0,
                resvPrice_2: 0,
                resvPrice_3: 0,
                resvPrice_tot: 0,
            },
            roomGubun: "",
            page: {
                limit: 50,  // 한페이지 표시 갯수 18
                offset: 0,  // %부터 보여주기
                total: '0', // 전체 갯수
                page: 1,    // 현제 페이지
                length: 1   // 페이지 표시 갯수
            },
            search: {
                searchGubun: "0",
                searchText: "",

            },
            searchDate: {
                startDate: "",
                endDate: "",
                isRegDate: "N",
            }

        }),
        created: function () {
            // 맨위로
            window.scrollTo(0, 0);
            //this.$isAdminCheck(this.$store.state.admin.isLogin);
            //this.$isAuthCheck(this.$getCookie("AUTH"));

            this.getList();

        },
        methods: {
            getList(val) {

                let _this = this;

                let formdata = {};

                if (val !== undefined) {

                    formdata = val;
                } else {
                    formdata = {}
                }


                return this.$store.dispatch("admin/getStatSum", formdata)
                    .then((resp) => {
                        setTimeout(() => {

                            this.statInfo = {
                                resvPrice_0: 0,
                                resvPrice_1: 0,
                                resvPrice_2: 0,
                                resvPrice_3: 0,
                                resvPrice_tot: 0,
                            };

                            resp.message.forEach(function (e) {

                                _this.statInfo.resvPrice_tot += e.totResvPrice;

                                switch (e.payType) {
                                    case "0" : {

                                        _this.statInfo.resvPrice_0 = e.totResvPrice;
                                        break;
                                    }
                                    case "1" : {
                                        _this.statInfo.resvPrice_1 = e.totResvPrice;
                                        break;
                                    }
                                    case "2" : {
                                        _this.statInfo.resvPrice_2 = e.totResvPrice;
                                        break;
                                    }
                                    case "3" : {
                                        _this.statInfo.resvPrice_3 = e.totResvPrice;
                                        break;
                                    }

                                }

                            });

                        }, 300)
                    })
                    .catch((err) => {
                    })
            },
            initPrice() {

            },
            menuClick(route, roomGubun) {
                //this.$store.commit("admin/SAVE_ROOM_GUBUN", roomGubun);
                //this.$router.push({path: route});

                if (roomGubun != null) {
                    location.href = route + '?roomGubun=' + roomGubun;
                } else {
                    location.href = route;
                }

            },
            setSearchBtn(val) {

                if (val === 0) {

                    this.searchDate.endDate = "";
                    this.searchDate.startDate = "";

                } else {
                    let date = new Date();
                    let year = date.getFullYear();
                    let month = ("0" + (1 + date.getMonth())).slice(-2);
                    let day = ("0" + date.getDate()).slice(-2);

                    this.searchDate.endDate = year + "-" + month + "-" + day;

                    // val(달) 전
                    let monthAgo = new Date(date.setMonth(date.getMonth() - val));

                    let year1 = monthAgo.getFullYear();
                    let month1 = ("0" + (1 + monthAgo.getMonth())).slice(-2);
                    let day1 = ("0" + monthAgo.getDate()).slice(-2);

                    this.searchDate.startDate = year1 + "-" + month1 + "-" + day1;
                }

            },
            searchClick() {

                let formdata = {};

                let startDate = $('#startDate').val();
                let endDate = $('#endDate').val();

                if (startDate.length > 0) {
                    if (this.searchDate.isRegDate === 'N') {
                        formdata['resvStartDate'] = startDate;
                    } else {
                        formdata['startDate'] = startDate;
                    }

                }

                if (endDate.length > 0) {
                    if (this.searchDate.isRegDate === 'N') {
                        formdata['resvEndDate'] = endDate;
                    } else {
                        formdata['endDate'] = endDate;
                    }

                }

                formdata['roomGubun'] = this.roomGubun;

                this.getList(formdata);

            },
            selectRoomGubun(e){

                let formdata = {
                    roomGubun: e.target.value
                }

                this.getList(formdata);
            }
        },
        mounted() {
            this.$nextTick(() => {

            })
        },
        computed: {
        },
    }
</script>

<style>

</style>

